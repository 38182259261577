import * as React from 'react'
import { Fade } from 'react-reveal'

class Slide {
    constructor(title) {
        this.title = title;
    }
}

const allSlides = [
    new Slide("Target Engineering"),
    new Slide("Data Analysis"),
    new Slide("Red Teaming"),
    new Slide("War Gaming"),
    new Slide("Intelligence Operations"),
    new Slide("Innovation"),
]


export const Slider = (props) => {
    const [ curSlide, setCurSlide] = React.useState(0)
    const [ faded, setFaded] = React.useState(false)
    
    React.useEffect(() => {
        const adjustSlide = () => {
            setFaded(false)

            if (curSlide < (allSlides.length - 1)) {
                setCurSlide(curSlide + 1)
                return
            } 
            setCurSlide(0)
            return
        }

        const newInt = setInterval(() => {
            adjustSlide()
        }, 2000);

        return () => clearInterval(newInt)
    }, [curSlide])

    React.useEffect(() => {
    }, [curSlide])

    React.useEffect(() => {
        setFaded(true)
    }, [faded])

    return (
        <div className='sliderClass'>
            <Fade when={faded}>
            <div>
            {
                allSlides[curSlide].title
            }
            </div>
            </Fade>
        </div>
    )
}