import './CircleSlide.css'
import * as React from 'react'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export const CircleSlide = (props) => {

    const [ curSlide, setSlide ] = React.useState(0)

    const increaseSlide = () => {
        if (curSlide >= 3) {
            setSlide(0)
        } else {
            setSlide(curSlide + 1)
        }
    }

    const decreaseSlide = () => {
        if (curSlide <= 0) {
            setSlide(3)
        } else {
            setSlide(curSlide - 1)
        }
    }

    const getSlide = (i) => {

        if (curSlide === 0) {
            switch (i) {
                case 0:
                    return "slidePosition1" 
                case 1:
                    return "slidePosition2" 
                case 2:
                    return "slidePosition3" 
                case 3:
                    return "slidePosition4" 
                default:
                    return "slidePosition1" 
            }
        } else if (curSlide === 1) {
            switch (i) {
                case 0:
                    return "slidePosition4" 
                case 1:
                    return "slidePosition1" 
                case 2:
                    return "slidePosition2" 
                case 3:
                    return "slidePosition3" 
                default:
                    return "slidePosition1" 
            }
        }else if (curSlide === 2) {
            switch (i) {
                case 0:
                    return "slidePosition3" 
                case 1:
                    return "slidePosition4" 
                case 2:
                    return "slidePosition1" 
                case 3:
                    return "slidePosition2" 
                default:
                    return "slidePosition1" 
            }
        } else if (curSlide === 3) {
            switch (i) {
                case 0:
                    return "slidePosition2" 
                case 1:
                    return "slidePosition3" 
                case 2:
                    return "slidePosition4" 
                case 3:
                    return "slidePosition1" 
                default:
                    return "slidePosition1" 
            }
        }






    }

    React.useEffect(() => {
        console.log(curSlide)
    }, [curSlide])

    return (
        <div>
            <div className='container' >
                <div className={"slide " + getSlide(0) + (curSlide === 0 ? " curSlide" : ' backSlide')} style={{backgroundColor: '#000'}}>
                    <div style={{minHeight: '420px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '10px'}}>
                        <h4 style={{color: '#FFF'}}>Target Engineering Analysis</h4>
                        <div>
                        <img src={process.env.PUBLIC_URL + '/images/tea.png'} alt="Target engineering analysis" style={{maxWidth: '200px', padding: '50px'}} />
                        </div>
                        <div>
                        <Link to={'/services'} className='destyle' >
                        <Button style={{minWidth: '200px'}} className='teaBtn' variant="contained" >View</Button>
                        </Link>
                        </div>
                    </div>
                </div>
                <div className={"slide " + getSlide(1) + (curSlide === 1 ? " curSlide" : ' backSlide')} style={{backgroundColor: '#595959'}}>
                    <div style={{minHeight: '420px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '10px'}}>
                        <h4 style={{color: '#fff'}}><span style={{color: '#ffa3a3'}}>Red</span> Teaming & Wargaming</h4>
                        <div>
                        <img src={process.env.PUBLIC_URL + '/images/rt.png'} alt="Red Teaming & Wargaming" style={{maxWidth: '150px', padding: '50px'}} />
                        </div>
                        <div>
                            <Link to={'/services#1'} className='destyle' >
                            <Button style={{minWidth: '200px'}} className='rtBtn' variant="contained">View</Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"slide " + getSlide(2) + (curSlide === 2 ? " curSlide" : ' backSlide')} style={{backgroundColor: '#ffc000'}}>
                    <div style={{minHeight: '420px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '10px'}}>
                        <h4 style={{color: '#000'}}>Intelligence Operations</h4>
                        <div>
                        <img src={process.env.PUBLIC_URL + '/images/io.png'} alt="Intelligence Operations" style={{maxWidth: '200px', padding: '50px'}} />
                        </div>
                        <div>
                            <Link to={'/services#2'} className='destyle' >
                                <Button style={{minWidth: '200px'}} className='ioBtn'>View</Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"slide " + getSlide(3) + (curSlide === 3 ? " curSlide" : ' backSlide')} style={{backgroundColor: '#d9d9d9'}}>
                    <div style={{minHeight: '420px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '10px'}}>
                        <h4 style={{color: '#000'}}>STAG TEAMs</h4>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/images/teams.png'} alt="STAG TEAMs"style={{maxWidth: '200px', padding: '50px'}}/>
                        </div>
                        <div>
                            <Link to={'/services#3'} className='destyle' >
                                <Button style={{minWidth: '200px'}} className='teamBtn'>View</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div id="buttonContainer">
                    <div id="buttonOne" onClick={increaseSlide}><div variant="contained"><KeyboardDoubleArrowRightIcon className='yellow' style={{paddingTop: '3px', paddingLeft: '3px'}}/></div></div>
                    <div id="buttonTwo" onClick={decreaseSlide}><div variant="contained"><KeyboardDoubleArrowLeftIcon className='yellow' style={{paddingTop: '3px', paddingRight: '3px'}}/></div></div>
                </div>
            </div>
        </div>
    )
}
