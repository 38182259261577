import * as React from 'react'
import { Button, Container, Grid } from "@mui/material"
import { Fade, Slide } from "react-reveal"
import { Link } from "react-router-dom"
import { Experience } from "../components/Experience"
import { Slider } from "../components/Slider"
import { ScrollTop } from "../components/Utils"
import { CircleSlide } from '../components/CircleSlide'

export const Home = (props) => {

    React.useEffect(() => {
        ScrollTop()
    }, [])

    return (
        <div>

        <Container>
            <div className="fullSize">
                <Fade>
                    <h1>Strategic Technologies Analytics Group</h1>
                </Fade>
                <div>
                    <Slider />
                </div>
            </div>
            <div>
                <Experience />
            </div>

            <Grid container>
                <Grid item xs={12} md={12} style={{padding: '20px', display: 'flex', justifyContent: 'center', marginBottom: '200px'}}>
                    <Fade>
                        <img src={process.env.PUBLIC_URL + '/images/staglogotrans.png'} alt="STAG" />
                    </Fade>
                </Grid>
            </Grid> 
        </Container> 

        <div className='bgBox'>
            <Grid container>
            <Grid item xs={12} md={12} style={{textAlign: 'center'}}>
                <Fade>
                <h2>Services</h2>

                { /* Circle Slide Implementation */ }
                <CircleSlide />

                </Fade>
            </Grid>
            </Grid>
        </div>

        <Container>  
            <Grid container style={{paddingBottom: '200px'}}>

                <Grid item xs={12} md={6} style={{padding: '20px'}}>
                    <Slide up>
                    <div className='centered'>
                        <h2 className="centered">Mission</h2>
                        <Link to="/mission" className="destyle">
                        <Button color="primary" variant="contained" className="bShadow" style={{minWidth: '250px', marginTop: '50px'}}>View</Button>
                        </Link>
                    </div>
                    </Slide>
                </Grid>
                <Grid item xs={12} md={6} style={{padding: '20px'}}>
                    <Fade>
                    <img src={process.env.PUBLIC_URL + '/images/globe.jpg'} alt="globe" style={{maxWidth: '100%', borderRadius: '20px'}}/>
                    </Fade>
                </Grid>
            </Grid>
        </Container>
        </div>
    )
}