import * as React from 'react'
import { Container, Grid, Button } from "@mui/material"
import { Fade } from "react-reveal"
import { ScrollTop } from "../components/Utils"
import { useLocation } from 'react-router-dom'

class ContentSlide {
    constructor(title, content) {
        this.title = title;
        this.content = content;
    }
}

const allSlides = [
    new ContentSlide("Target Engineering Analysis", 
        (
            <div>
            <p>In order to achieve freedom of movement in a target space a thorough understanding of the attack surface is critical to mission success. STAG has the best analysts in the business who have analyzed and prosecuted the hardest target sets that are in the world today. Coming from the elite sections of the intelligence community, industry and academia, STAG operators/analysts apply a home grown process that enhances data fusion expediting product delivery.</p>
            <ul>
                <li>Cradle-to-Grave Assessment of Target</li>
                <li>Evaluations of strategic/operational plans</li>
                <li>Target Package Development/Refinement</li>
                <li>Technical Engineering Analysis</li>
                <li>Defensive Posture Assessment</li>
                <li>Modeling of effects on target</li>
                <li>Access strategy determination/evaluation</li>
                <li>Operational/Tactical Intelligence Requirements Generation</li>
                <li>Tool Development/Refinement</li>
                <li>Tactics Documentation/Nomination</li>
                <li>Analytics Framework Creation</li>
                <li>Vignette Construction</li>
            </ul>
            </div>
        )
    ),
    new ContentSlide("Red Teaming & Wargaming",
    (
        <div>
        <p>Every STAG vignette/simulation is built on decades of expertise in operations, evaluation, simulation, and analytical techniques that are fused with the latest technology innovations and learning theory creating a next generation simulation framework that is rinseable, repeatable and scientific. Our wargames and exercises center on real world events that have been analyzed and enhanced to achieve the desired training milestones. Our unique access to this data enables STAG to offer technically sound scenarios not currently offered.</p>
        <ul>
            <li>In depth scenario development based on real world recaps</li>
            <li>Operations / Tradecraft Instruction (Entry – Advanced)</li>
            <li>Live, virtual and constructive training systems and services</li>
            <li>Large-scale exercise support</li>
            <li>Weapons engagement simulators</li>
            <li>Modeling and Simulation of Effects</li>
            <li>High-fidelity customized and man-in-the-loop system simulations</li>
            <li>Mobile Training Teams</li>
            <li>Traditional classroom instruction</li>
        </ul>
        </div>
    )),
    new ContentSlide("Intelligence Operations",
    (
        <div>
            <p>STAG intelligence professionals have a broad range of capabilities and experience working some of the world’s most challenging situations/environments. We currently provide strategic, operational & tactical level intelligence support to multiple agencies within the intelligence community and DoD. STAG analysts provide analysis/products in:</p>
            <ul>
            <li>OSINT</li>
            <li>SIGINT</li>
            <li>HUMINT</li>
            <li>GEOINT</li>
            <li>Counter-Intelligence</li>
            <li>MASINT</li>
            </ul>
            <p>
            Our cleared intelligence professionals have consistently demonstrated creativity, discretion, and the highest ethical standards in support of U.S. government-sponsored and endorsed objectives. We continue to exceed the expectations of our customers by pushing the limits of traditional analysis with pairings from the best technologies in artificial intelligence, machine learning & heuristic analysis. This unique pairing enables STAG professionals to provider higher quality products, with less people, in a shorter timeframe.
            </p>
        </div>
    )),
    new ContentSlide("STAG Teams",
        <Grid container>
            <Grid item xs={12} md={6}>
                <div>
                <h4 className='centered'>Space</h4>
                Space Operations Specialist x1<br />
                GEOINT Specialist x2<br />
                Overhead Mission Planner x1<br />

                <h4 className='centered'>Cyber</h4>
                Cyber Operator (Offense/Defense) x1<br />
                Digital Network Intelligence Specialist x2<br />
                Joint Mission Fires Planner x1<br />

                <h4 className='centered'>Special Operations</h4>

                Special Operations Specialist x1<br />
                Human Network Analyst x1<br />
                All-Source Fusion Analyst x1<br />
                Joint Task Force Planner x1<br />

                <h4 className='centered'>Additional Team Options</h4>

                Acquisitions Specialist<br />
                Data Scientist<br />
                Human Factors Analyst<br />
                ICS Engineer<br />
                </div>
            </Grid>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={process.env.PUBLIC_URL + '/images/team.png'} alt="STAG TEAMS" style={{maxWidth: '80%'}}/>
            </Grid>

        </Grid>
    )
]


export const Services = (props) => {

    const location = useLocation()

    let idToLoad = location.hash.replace("#",'') 

    const [ contentObj, setContent ] = React.useState(allSlides[idToLoad || 0])
    const [ faded, setFaded] = React.useState(false)

    React.useEffect(() => {
        setFaded(true)
    }, [faded])

    React.useEffect(() => {
        ScrollTop()
    }, [])

    const setNewContent = (c) => {
        setContent(c)
        setFaded(false)
    }

    return (
        <Container style={{paddingBottom: '200px'}}>
            <div className="biggerFont" style={{minHeight: '200px'}}>
                <Fade>
                    <h1 className="centered" style={{paddingTop: '100px'}}>Services</h1>
                </Fade>
            </div>

            <Grid container >
                <Grid item xs={12} md={3} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    { allSlides.map((slide) => {
                        return (
                            <Button className="mb" color="primary" variant="contained" key={slide.title} onClick={() => {setNewContent(slide)}}>{ slide.title }</Button>
                        )
                    })}
                </Grid>
                <Grid item xs={12} md={9} style={{padding: '20px', 
                    display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight: '710px',
                     fontFamily: 'Sans-serif', lineHeight: '1.5em', backgroundColor: '#000', borderRadius: '20px',
                    boxShadow: '0 0 3px 1px #fff2'}}>
                    <Fade when={faded}>
                    <h2 style={{textAlign: 'center'}}>{ contentObj.title }</h2>
                    { contentObj.content }
                    </Fade>
                </Grid>
            </Grid>
        </Container>
    )
}