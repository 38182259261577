import * as React from 'react'
import { Container, Grid } from "@mui/material"
import { Fade, Slide } from "react-reveal"
import { ScrollTop } from "../components/Utils"

export const Mission = (props) => {
    React.useEffect(() => {
        ScrollTop()
    }, [])

    return (
        <Container>
            <Grid container>
                <Grid item xs={12} md={12} order={{xs: 1, md: 1}} style={{paddingTop: '50px', paddingBottom: '50px'}}>
                    <Fade>
                        <h1 className='centered' >Our Mission</h1>
                    </Fade>
                </Grid>
                <Grid item xs={12} md={9} order={{xs: 2, md: 2}} >
                    <Fade>
                        <p className='blackRoundBox' style={{marginTop: '75px', marginBottom: '40px', textAlign: 'justify'}}>Strategic Technologies Analytics Group (STAG) is a premier solutions provider to the National Security and Defense communities around the world. A Veteran-Owned Emerging Small Business based in San Antonio, Texas and started by military veterans whose commitment, technical acumen, and domain knowledge continues with peerless service to both our clients and mission partners.</p>
                    </Fade>
                </Grid>     
                <Grid item xs={12} md={3} style={{padding: '20px', textAlign: 'center'}} order={{xs: 3, md: 3}} >
                    <Fade>
                    <img src={process.env.PUBLIC_URL + '/images/tea.png'} alt="Mission" style={{maxWidth: '250px', borderRadius: '20px'}}/>
                    </Fade>
                </Grid>  

                <Grid item xs={12} md={3} style={{padding: '20px', textAlign: 'center'}} order={{xs: 5, md: 4}}>
                    <Fade>
                    <img src={process.env.PUBLIC_URL + '/images/io.png'} alt="Mission" style={{maxWidth: '250px', borderRadius: '20px' }}/>
                    </Fade>
                </Grid>  
                <Grid item xs={12} md={9} style={{padding: '20px'}} order={{xs: 4, md: 5}}>  
                    <Slide up>
                    <p className='blackRoundBox' style={{marginBottom: '40px', textAlign: 'justify'}}>STAG provides multi-domain technology solutions coupled with the best professionals sourced from their respective fields of intelligence, cyber, electronic warfare and special operations communities. Our team members serve at all levels of operations from on-keyboard cyber warfare experts to national level advisors on intelligence operations. STAG utilizes proven methodologies that produces responsive, highly equipped teams providing solutions currently not addressed in the operational community. Partnered with STAG, our customers are uniquely equipped to defend against the adversary and if needed to take the fight to them. STAG is the solution to the demand for high-end engineering / computer science / intelligence professionals needed to support next generation warfighters on the battlefields of tomorrow.</p>
                    </Slide>
                </Grid>
                <Grid item xs={12} md={12} style={{padding: '20px'}} order={{xs: 6, md: 6}}>
                    <Slide up>
                    <p className='blackRoundBox' style={{marginBottom: '40px', textAlign: 'justify'}}>STAG’s areas of proficiency include: Cyber/Intelligence Operations, Red Teaming, National Security Services, Electronic Warfare, Strategic Planning, Target Engineering Analysis, Training Support and application development.</p>
                    </Slide>
                </Grid> 
            </Grid>
           

        </Container>
    )
}