import { Container, Grid } from "@mui/material"
import { useParams } from "react-router-dom";
import { AllJobs } from '../components/Jobs'
import { ScrollTop } from "../components/Utils";
import * as React from 'react'
import { Fade } from "react-reveal";

export const Job = (props) => {
    let { id } = useParams();
    let JobIndex = parseInt(id);
    const tempJ = AllJobs[JobIndex];

    const [ triggered, setTriggered] = React.useState(false)
    const [ curJob, setJob] = React.useState(tempJ)

    React.useEffect(() => {
        setTriggered(false)
    }, [JobIndex])

    React.useEffect(() => {
        ScrollTop()
        setJob(tempJ)

        setTimeout(() => {setTriggered(true)}, 500)
    },[tempJ])

    return (
        <Container style={{paddingBottom: '150px'}}>
            <Fade when={triggered}>
            <Grid container style={{paddingTop: '50px'}}>
                <Grid item xs={12} md={12}>
                    <h2>{ curJob.title }</h2>
                </Grid>
                <Grid item xs={12} md={12} className='blackRoundBox' style={{paddingBottom: '150px'}}>
                    { curJob.desc }
                </Grid>
            </Grid>
            </Fade>
        </Container>
    )
}