import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';


class Page {
    constructor(name, link) {
        this.name = name;
        this.link = link;
    }
}

const pages = [
    new Page('Home', '/'),
    new Page('Services', '/services'),
    new Page('Mission', '/mission'),
    new Page('Careers', '/careers'),
];


export const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'GoodTimes',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
              <Link to="/" className='destyle'>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={process.env.PUBLIC_URL + '/images/staglogo.png'} className="logoImage" alt="STAG" />
                STAG
              </div>
              </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle white">
                    <MenuItem >
                    <Typography textAlign="center" style={{fontFamily: 'GoodTimes'}} className='menuLink'>{page.name}</Typography>
                    </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>


          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'GoodTimes',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              alignItems: 'center',
            }}
          >
                <Link to="/" className='destyle'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src={process.env.PUBLIC_URL + '/images/staglogo.png'} className="logoImage" alt="STAG" />
                    STAG
                </div>
                </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            {pages.map((page) => (
                <Link to={page.link} onClick={handleCloseNavMenu} key={page.name} className="destyle">
                    <Button
                        sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'GoodTimes'}} 
                    >
                        {page.name}
                    </Button>
                </Link>
            ))}
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
};