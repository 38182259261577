import { Container, Grid } from "@mui/material"
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const DestyledLink = (props) => <a href={props.link} className="destyle" target="_blank" rel="noreferrer">{ props.children }</a>

export const Footer = (props) => {

    return (
        <div className="footer">
            <Container>
                <Grid container>
                    <Grid item xs={12} md={12} style={{fontFamily: 'sans-serif', lineHeight: '1.5em', paddingBottom: '100px', textAlign: 'justify'}}>
                        <p>Strategic Technologies Analytics Group, LLC provides equal employment opportunities (EEO) to all employees and applicants for employment without regard to race, color, religion, sex, national origin, age, disability or genetics. In addition to federal law requirements, Strategic Technologies Analytics Group, LLC complies with applicable state and local laws governing nondiscrimination in employment in every location in which the company has facilities. This policy applies to all terms and conditions of employment, including recruiting, hiring, placement, promotion, termination, layoff, recall, transfer, leaves of absence, compensation and training.</p>
                        <p>Strategic Technologies Analytics Group, LLC expressly prohibits any form of workplace harassment based on race, color, religion, gender, sexual orientation, gender identity or expression, national origin, age, genetic information, disability, or veteran status. Improper interference with the ability of Strategic Technologies Analytics Group, LLC’s employees to perform their job duties may result in discipline up to and including discharge.</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h3>Contact Us</h3>
                    </Grid>

                    <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div style={{padding: '10px'}}>
                            <DestyledLink link="https://www.facebook.com/StrategicTechnologiesAnalyticsGroup/">
                            <div style={{padding: '5px'}}><FacebookIcon /></div>
                            </DestyledLink>
                        </div>
                        <div style={{padding: '10px'}}>
                            <DestyledLink link="https://twitter.com/stag_tech">
                            <div style={{padding: '5px'}}><TwitterIcon /></div>
                            </DestyledLink>
                        </div>
                        <div style={{padding: '10px'}}>
                            <DestyledLink link="https://www.linkedin.com/company/36022287/">
                            <div style={{padding: '5px'}}><LinkedInIcon /></div>
                            </DestyledLink>
                        </div>
                    </Grid>



                    <Grid item xs={12} md={12} className="padded">
                        <span style={{fontSize: '0.8em', fontFamily: 'sans-serif'}}>&copy; Copyright STAG LLC 2022.  All Rights Reserved</span>
                    </Grid>

                </Grid>
            </Container>
            
        </div>
    )
}