import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Home } from './pages/Home'
import { Navbar } from './components/Navbar'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Services } from './pages/Services';
import { Careers } from './pages/Careers';
import { Mission } from './pages/Mission';
import { Footer } from './components/Footer';
import { Contact } from './components/Contact';
import { Jobs } from './components/Jobs';
import { Job } from './pages/Job';

const theme = createTheme({
  palette: {
    primary: {
      light: '#333',
      main: '#111',
      dark: '#222',
      contrastText: 'rgb(204, 153, 0)',
    },
    secondary: {
      light: 'rgb(204, 153, 0)',
      main: 'rgb(204, 153, 0)',
      dark: '#ba000d',
      contrastText: '#fff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="bg hideOverflow">
          <img src={process.env.PUBLIC_URL + '/images/bg.png'} alt='bg' className="backgroundImage"/>
          <div id="overlay"></div>
        </div>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/careers" element={<Careers />} />
          <Route exact path="/mission" element={<Mission />} />
          <Route exact path="/job/:id" element={<Job />} />
        </Routes>
        <Jobs />
        <Contact />
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
