


export const Experience = (props) => {


    return (
        <div style={{display: 'flex', flexDirection: 'column', paddingBottom: '200px'}}>
            <h3>Experience</h3>
            <div className="expBar">Intelligence</div>
            <div className="expBar">Cyber Warfare Operations</div>
            <div className="expBar">Electronic Warfare</div>
            <div className="expBar">Special Operations</div>
        </div>
    )
}