
import { Grid, Container } from "@mui/material"
import { Fade, Slide } from "react-reveal"


export const Contact = (props) => {



    return (
        <Container>
            
            <Grid container style={{marginBottom: '150px'}}>
                <Grid item xs={12} md={12}>
                    <Fade>
                    <h2 style={{}}>Contact Us</h2>
                    </Fade>
                </Grid>
                <Grid item xs={12} md={12} className='bShadow' style={{padding: '20px', borderRadius: '20px', backgroundColor: '#0009'}}>
                    <Slide up>
                    <p>For any general inquiries, please use the email below.</p>
                    <p style={{textAlign: 'center'}}>Email: <a href="mailto:hr@stagtechnology.com" className="destyle yellow" target={'_blank'} rel="noreferrer">hr@stagtechnology.com</a></p>
                    </Slide>
                </Grid>
            </Grid>
        </Container>
    )
}