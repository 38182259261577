import * as React from 'react'
import { Grid, Container } from "@mui/material"
import { Fade } from "react-reveal"
import { ScrollTop } from "../components/Utils"

export const Careers = (props) => {

    React.useEffect(() => {
        ScrollTop()
    }, [])

    return (
        <Container>
            <div className="biggerFont" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                <Fade>
                    <h1 className='centered'>Careers</h1>
                </Fade>
            </div>

            <Grid container style={{paddingBottom: '100px'}}>
                <Grid item xs={12} md={3} style={{display: 'flex', alignItems: 'center'}}>
                    <h3>Our Culture</h3>
                </Grid>
                <Grid item xs={12} md={9} style={{textAlign: 'justify', padding: '20px'}}>
                    <Fade>
                    <p className="blackRoundBox">STAG cultivates a motivating organizational culture that inspires excellence in execution. Adaptation and constant innovation is not just a priority, but rather a strategic imperative. The STAG cultural foundation is based on strong core values, beliefs, and rituals that lead to results. Core values define the mindset and belief system that galvanize the team and provide direction. Most people focus on the results at work, thinking that will make them successful. The truth is, we can be more successful by focusing on the process not the outcome.</p>
                    </Fade>
                </Grid>
            </Grid>

            <Grid container style={{paddingBottom: '100px'}}>
                <Grid item xs={12} md={9} style={{textAlign: 'justify', padding: '20px'}} order={{xs: 2, md: 1}}>
                    <Fade>
                    <p  className="blackRoundBox">STAG provides program management, information technology systems design and maintenance, operational research and analysis, subject matter expertise and other capabilities to strategic components of the Intelligence, National Security, and U.S. business communities. The work environment is an often fast-paced, dynamic and a challenging one – especially true for those working within these communities which require consummate professionals capable of analytical thinking, and adhering to tight schedules. Totally committed to our customer’s mission, responsiveness, and innovative thinking, STAG consistently provides critical domain & technology expertise to key customer projects.</p>
                    </Fade>
                </Grid>
                <Grid item xs={12} md={3} style={{display: 'flex', alignItems: 'center'}} order={{xs: 1, md: 2}}>
                    <h3>OUR CHALLENGING AND MEANINGFUL WORK</h3>
                </Grid>
            </Grid>

            <Grid container style={{paddingBottom: '100px'}}>
                <Grid item xs={12} md={3} style={{display: 'flex', alignItems: 'center'}}>
                    <h3>OUR TOTAL VALUE</h3>
                </Grid>
                <Grid item xs={12} md={9} style={{textAlign: 'justify', padding: '20px'}}>
                    <Fade>
                    <p  className="blackRoundBox">OUR TOTAL VALUE
In addition to receiving a competitive salary and having an equal opportunity for professional development and advancement, employees have access to benefits specifically designed to attract and retain talent. A true balance between work and life comes with knowing that your life activities are integrated, not separated. STAG supports a healthy work-life balance and offers a rich benefits package which includes:
                    <ul>
                    <li>401k with excellent Company Matching</li>
                    <li>Business Development and Referral Bonuses</li>
                    <li>Medical, Dental, and Vision Reimbursement Plans</li>
                    <li>Life and Accident Insurance</li>
                    <li>Disability Insurance</li>
                    <li>Access to an Employee Assistance Program (EAP)</li>
                    <li>Generous Paid Time Off Policy</li>
                    <li>Tuition Assistance</li>
                    <li>And much, much more…</li>
                    </ul>
                    </p>
                    </Fade>
                </Grid>
            </Grid>
        </Container>
    )
}